.blog-details {
  padding: 20px;
  margin-bottom: 10px;
}

.blog-title {
  font-size: 36px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.blog-preview {
  font-size: 20px;
  color: #555;
  margin-bottom: 10px;
}

.blog-content {
  font-size: 16px;
  color: #333;  
}

.blog-image-container {
  text-align: center;
  margin-bottom: 20px;
}

.blog-image {
  max-width: 100%;
  max-height: 400px;
  border-radius: 10px;
  object-fit: cover;
}
