/* Common styles for all screen sizes */
.blogs {
  padding: 20px;
  margin-bottom: 10px;
}

.blogs h2 {
  font-size: 36px;
  margin-bottom: 30px;
  color: #333;
  text-align: center;
}

.blog-item {
  display: flex;
  flex-direction: column; /* Stack the content vertically */
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.blog-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.blog-content {
  width: 100%;
}

.blog-image {
  width: 100%; /* Set the image width to 100% to make it full-width */
  height: 300px; /* Set the image height to 300px */
  overflow: hidden; /* Ensure the image does not overflow its container */
  border-radius: 10px;
  margin-bottom: 20px; /* Add some space between the image and the content below it */
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-info {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.blog-title {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;
}

.blog-preview {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

/* Add some spacing between blogs */
.blog-item:not(:last-child) {
  margin-bottom: 40px;
}

/* Add margin to the last blog item */
.blog-item:last-child {
  margin-bottom: 60px;
}

/* Responsive styles */
@media screen and (min-width: 576px) {
  .blog-item {
    flex-direction: row; /* Revert back to horizontal layout for larger screens */
    align-items: flex-start;
  }

  .blog-image {
    flex: 0 0 400px; /* Set the image width to 400px */
    height: 300px; /* Set the image height to 300px */
    margin-bottom: 0; /* Reset the margin to remove the space between image and content */
  }

  .blog-info {
    margin-left: 20px; /* Add space between image and content */
  }
}
