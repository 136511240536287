.contact {
    padding: 20px;
}

.contact h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.contact p {
    font-size: 16px;
    line-height: 1.6;
}

.contact-info {
    margin-top: 20px;
}

.email p {
    font-size: 16px;
    margin-bottom: 10px;
}

.social-media p {
    font-size: 16px;
    margin-bottom: 5px;
}

.social-media ul {
    list-style: none;
    padding-left: 0;
}

.social-media li {
    margin-bottom: 5px;
}

.social-media a {
    text-decoration: none;
    color: #007bff;
}

.social-media a:hover {
    text-decoration: underline;
}