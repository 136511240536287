/* Header.css */

header {
  background-color: #333;
  color: #fff;
  padding: 10px;
}

nav {
  display: flex;
  align-items: center;
}

.logo {
  flex: 1;
}

h1 {
  margin: 0;
  font-size: 24px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
}

.nav-links a:hover {
  background-color: #555;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px;
  transition: transform 0.3s;
}

.menu-icon.active .menu-line:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon.active .menu-line:nth-child(2) {
  opacity: 0;
}

.menu-icon.active .menu-line:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Add responsive styles */
@media screen and (max-width: 768px) {
  .logo {
    flex: 2;
  }

  .menu-icon {
    display: block;
  }

  .nav-links {
    display: none;
  }

  .nav-links.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #444;
    z-index: 1;
  }

  .nav-links.show li {
    padding: 10px;
  }

  .nav-links.show a {
    border-radius: 0;
    display: block;
  }
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .logo {
    flex: 2;
    /* Increase the logo flex to occupy more space on smaller screens */
  }

  .nav-links {
    display: none;
    /* Hide the navigation links on smaller screens */
  }

  .nav-links.show {
    display: flex;
    /* Show the navigation links in a vertical list when menu is open */
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #444;
    z-index: 1;
  }

  .nav-links.show li {
    padding: 10px;
  }

  .nav-links.show a {
    border-radius: 0;
    display: block;
  }
}