.about-us {
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.about-us h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.about-us p {
    font-size: 16px;
}