.home {
    padding: 20px;
}

.home h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.home p {
    font-size: 16px;
    line-height: 1.6;
}